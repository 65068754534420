import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from './../../environments/environment';
import { APP_CONFIG, Config } from 'src/config';

@Injectable()
export class AuthService {

  oauthTokenUrl: string;
  jwtPayload: any;
  
  constructor(
      @Inject(APP_CONFIG) readonly config: Config,
      private http: HttpClient,
      private jwtHelper: JwtHelperService,
  ) {
    
    console.log('Get Configs: ', this.config);
    this.oauthTokenUrl = `${this.config.apiUrl}/oauth/token`;
    this.carregarToken();
    this.carregarRefreshToken();

  }

  login(usuario: string, senha: string): Promise<void> {
    const headers = new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded')
        .append('Authorization', 'Basic bWV1LWV4YW1lLXdlYjplbHBvcmd4YQ==');

    const body = `username=${usuario}&password=${senha}&grant_type=password`;

    return this.http.post(this.oauthTokenUrl, body,
        { headers, withCredentials: true })
        .toPromise()
        .then(response => {
          this.armazenarToken(response['access_token']);
          this.armazenarRefreshToken(response['refresh_token']);
          this.armazenarClienteCod(response['cod']);
        })
        .catch(response => {
          if (response.status === 400) {
            if (response.error.error === 'invalid_grant') {
              return Promise.reject('Usuário ou senha inválida!');
            }
          }

          return Promise.reject(response);
        });
  }

  obterNovoAccessToken(): Promise<void> {
    const headers = new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded')
        .append('Authorization', 'Basic bWV1LWV4YW1lLXdlYjplbHBvcmd4YQ==');

    const body = 'grant_type=refresh_token&refresh_token='+localStorage.getItem('refresh_token');

    return this.http.post(this.oauthTokenUrl, body,
        { headers, withCredentials: true })
        .toPromise()
        .then(response => {
          this.armazenarToken(response['access_token']);
          this.armazenarRefreshToken(response['refresh_token']);
          this.armazenarClienteCod(response['cod']);

          console.log('Novo access token criado!');

          return Promise.resolve(null);
        })
        .catch(response => {
          console.error('Erro ao renovar token.', response);
          return Promise.resolve(null);
        });
  }

  isAccessTokenInvalido() {
    const token = localStorage.getItem('token');

    return !token || this.jwtHelper.isTokenExpired(token);
  }

  limparAccessToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('clienteCode');
    this.jwtPayload = null;
  }

  temPermissao(permissao: string) {
    return this.jwtPayload && this.jwtPayload.authorities.includes(permissao);
  }

  temQualquerPermissao(roles) {
    for (const role of roles) {
      if (this.temPermissao(role)) {
        return true;
      }
    }

    return false;
  }

  private armazenarToken(token: string) {
    this.jwtPayload = this.jwtHelper.decodeToken(token);
    localStorage.setItem('token', token);
  }

  private armazenarRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }

  private armazenarClienteCod(cod: number) {
    localStorage.setItem("clienteCode", String(cod));
  }

  private carregarToken() {
    const token = localStorage.getItem('token');
    if (token) {
      this.armazenarToken(token);
    }
  }

  private carregarRefreshToken() {
    const token = localStorage.getItem('refresh_token');

    if (token) {
      this.armazenarRefreshToken(token);
    }
  }

}


